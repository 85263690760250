<template>
  <b-row class="info mx-0">
    <b-col cols="7">
      <h5 class="field-name">{{$t('Title')}}</h5>
      <RFInput label="Name" v-model="info.title.name" :readonly="!editable"/>
      <RFInputTranslations label="Translations" @change="infoTitleTranslations" :prefilled-translations="info.title.translations" :readonly="!editable"/>
      <RFSelect top-label="Default Value" v-model="info.title.defaultValue" :value="info.title.defaultValue" :list="titleValues || []" :readonly="!editable"/>

      <h5 class="field-name mt-4">{{$t('Description')}}</h5>
      <RFInput label="Name" v-model="info.description.name" :readonly="!editable"/>
      <RFInputTranslations label="Translations" @change="infoDescriptionTranslations" :prefilled-translations="info.description.translations" :readonly="!editable"/>
      <RFSelect top-label="Default Value" v-model="info.description.defaultValue" :value="info.description.defaultValue" :list="descriptionValues || []" :readonly="!editable"/>
    </b-col>
    <b-col cols="5" class="d-flex align-items-center">
      <div class="info-image">
        <div class="image" v-if="info.file && editable" :style="{ backgroundImage: `url(${getPreview(info.file)})` }"/>
        <div class="image" v-if="!editable" :style="{ backgroundImage: `url(${imagePath})`}"/>
        <RFFile class="my-1" size="small" label="Add Image" background="#fff" color="#5CB86C" border-color="#5CB86C" v-if="!info.file && editable" @file="info.file = $event"/>
        <div class="hover" v-if="info.file && editable">
          <FontAwesomeIcon class="delete-icon" color="#fff" size="lg" :icon="['fas', 'trash']" @click="deleteInfoImage"/>
        </div>
      </div>
    </b-col>
    <FontAwesomeIcon v-if="!loading && !confirmDelete" class="close-icon" size="lg" :icon="['fas', 'times-circle']" color="#FF5A5F" @click="editable ? deleteInfo() : confirmDelete = true"/>
    <transition name="fade">
      <div class="confirm-delete" v-if="confirmDelete">
        <RFButton size="small" background="#fff" color="#24425B" label="Confirm" @on-click="deleteInfo" v-if="!loading"/>
        <RFButton class="ml-2" size="small" background="#fff" color="#24425B" label="Cancel" @on-click="confirmDelete = false" v-if="!loading"/>
        <RFLoader color="#fff" v-if="loading"/>
      </div>
    </transition>
  </b-row>
</template>

<script>
import RFFile from '@/components/forms/RFFile'
import RFInput from '@/components/forms/RFInput'
import RFSelect from '@/components/forms/RFSelect'
import RFInputTranslations from '@/components/forms/RFInputTranslations'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'

import { getAsset } from '@/helpers'
import { adminService } from '@/services/admin'
import { mapState } from 'vuex'

export default {
  name: 'AdminInfo',
  components: {
    RFFile,
    RFInput,
    RFInputTranslations,
    RFSelect,
    RFButton,
    RFLoader,
  },
  props: {
    index: Number,
    information: Object,
    editable: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      info: null,

      titleValues: [],
      descriptionValues: [],
      imagePath: null,
      loading: false,
      confirmDelete: false,
    }
  },
  computed: {
    ...mapState({
      building: state => state.adminBuildingStore.building,
    })
  },
  beforeMount() {
    this.info = this.information

    if (this.editable) {
      this.info.title = {
        name: null,
        translations: null,
        defaultValue: null,
      }

      this.info.description = {
        name: null,
        translations: null,
        defaultValue: null,
      }
    }

    this.imagePath = getAsset(this.info.imagePath)
  },
  watch: {
    info: {
      deep: true,
      handler() {
        this.info.title.name = this.info.title.name ? this.info.title.name.toUpperCase() : null
        this.info.description.name = this.info.description.name ? this.info.description.name.toUpperCase() : null
        this.$emit('info', this.info, this.index)
      }
    }
  },
  methods: {
    infoTitleTranslations(translations) {
      this.info.title.translations = translations
      this.titleValues = Object.values(this.info.title.translations).map((value) => { return value ? { label: value, value } : {} })
      this.titleValues = this.titleValues.filter(defaultValue => Object.entries(defaultValue).length !== 0)
    },
    infoDescriptionTranslations(translations) {
      this.info.description.translations = translations
      this.descriptionValues = Object.values(this.info.description.translations).map((value) => { return value ? { label: value, value } : {} })
      this.descriptionValues = this.descriptionValues.filter(defaultValue => Object.entries(defaultValue).length !== 0)
    },
    getPreview(file) {
      return file ? URL.createObjectURL(file) : null
    },
    async deleteInfo() {
      if (!this.editable) {
        this.loading = true
        const { status } = await adminService.deleteInfo(this.info.id)

        if (status === 200) {
          const infos = this.building.infos

          infos.splice(this.index, 1)
          this.$store.commit('adminBuildingStore/setInfos', infos)
        }

        this.loading = false
      }

      this.confirmDelete = false
      this.$emit('delete', this.index)
    },
    deleteInfoImage() {
      this.info.file = null
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  position: relative;
  border: 1px solid $gray;
  padding: 20px 10px;

  .field-name {
    @include font-style($montserrat, 'semibold', $font-16);
    margin: 0;
    color: $dark-blue;
    border-bottom: 1px solid $gray;
  }
  .info-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 250px;
    border: 1px solid $gray;
    cursor: pointer;

    &:hover {
      .hover {
        opacity: 1;
      }
    }
    .hover {
      position: absolute;
      background-color: rgba($black, .4);
      width: 100%;
      height: 100%;
      z-index: 30;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .3s;

      .delete-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .image {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .trash-icon {
      position: absolute;
      bottom: -5px;
      right: -5px;
    }
  }
  .close-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    background-color: $white;
    border-radius: 50%;
  }
  .confirm-delete {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
  }
}
</style>

<template>
  <div class="admin-info-list">
    <template v-if="!editable">
      <AdminInfo class="mb-4" v-for="(info, index) in infos" :key="info.id" :information="info" :index="index"
        @delete="deleteInfo" :editable="editable"/>
    </template>
    <template v-else>
      <AdminInfo class="mb-4" v-for="(info, index) in editableInfos" :key="index" :information="info" :index="index"
        @delete="deleteInfo" :editable="editable"/>
    </template>
  </div>
</template>

<script>
import AdminInfo from '@/components/admin/AdminInfo'
import { mapState } from 'vuex'

export default {
  name: 'AdminInfoList',
  components: {
    AdminInfo,
  },
  props: {
    infos: Array,
    editable: {
      type: Boolean,
      default: true,
    },
    addInfo: Boolean,
  },
  data() {
    return {
      editableInfos: [],
    }
  },
  computed: {
    ...mapState({
      building: state => state.adminBuildingStore.building,
    })
  },
  methods: {
    deleteInfo(index) {
      if (this.editable) {
        this.editableInfos.splice(index, 1)
      }

      this.$emit('delete', this.building.infos)
    }
  },
  watch: {
    editableInfos: {
      handler() {
        this.$emit('infos', this.editableInfos)
      }
    },
    addInfo: {
      handler() {
        if (this.addInfo) {
          const emptyInfo = {
            title: null,
            description: null,
            file: null,
          }

          this.editableInfos.push(emptyInfo)
        }
      }
    }
  }
}
</script>

<style>

</style>